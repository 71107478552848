<template>
  <div>
    <Row :gutter="6" class="m-b-5">
      <i-col :xs="24" :sm="12" :md="6" :lg="3" class="m-b-5">
        <i-select size="small" v-model="assetId" @on-change="handleChangeAsset">
          <Option :value="-1">全部资产</Option>
          <i-option :value="item.id" v-for="item in assetArray" :key="item.id">{{ item.name }}</i-option>
        </i-select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="6" :lg="3" class="m-b-5">
        <i-select size="small" placeholder="站点（可通过关键字搜索）" clearable filterable v-model="query.stationId">
          <i-option :value="item.id" v-for="item in stationArray" :key="item.id">{{ assetId === -1 ? `${item.name} -
                      ${item.assetName}` : item.name }}</i-option>
        </i-select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="6" :lg="3" class="m-b-5">
        <i-select size="small" placeholder="资源类型（可通过关键字搜索）" clearable filterable v-model="query.resourcetypeId">
          <OptionGroup v-for="item in resourceTypeArray" :key="'re_parent_' + item.id" :label="item.name">
            <Option v-for="child in item.children" :value="child.id" :key="'re_child' + child.id">{{ child.name }}
            </Option>
          </OptionGroup>
        </i-select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="6" :lg="3" class="m-b-5">
        <i-select size="small" placeholder="资源位置" clearable v-model="query.position">
          <Option v-for="(item,index) in positionArray" :key="index" :value="item.value">{{item.name}}</Option>
        </i-select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="6" :lg="3" class="m-b-5">
        <i-select size="small" placeholder="资源状态" clearable v-model="query.enabled">
          <i-option v-for="item in statusArray" :key="'status_' + item.value" :value="item.value">{{
            item.name }}</i-option>
        </i-select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="6" :lg="3" class="m-b-5">
        <i-select size="small" placeholder="发布状态" clearable v-model="query.status">
          <i-option v-for="item in publishStatusArray" :key="'status_' + item.value" :value="item.value">{{item.name }}</i-option>
        </i-select>
      </i-col>

      <i-col :xs="24" :sm="12" :md="4" :lg="4" class="m-b-5">
        <i-input size="small" placeholder="关键字：支持名称查询" v-model="query.keyword"></i-input>
      </i-col>
      <i-col :xs="24" :sm="12" :md="2" :lg="2" class="m-b-5">
        <i-button size="small" type="primary" icon="ios-search" @click="handleSearch()">搜索</i-button>
      </i-col>
    </Row>

    <Row class="m-b-10">
      <i-col span="24">
        <i-button size="small" class="m-l-5" type="success" @click="handleAdd">新增资源</i-button>
        <i-button size="small" class="m-l-5" type="success" :disabled="selectionInventoryIds.length===0" @click="handleSettingAdditional">批量设置商品附加描述</i-button>
        <i-button size="small" class="m-l-5" type="success" :disabled="selectionInventoryIds.length===0" @click="handleSettingPhoto">批量设置商品主图</i-button>
        <i-button size="small" class="m-l-5" type="success" :disabled="selectionInventoryIds.length===0" @click="handleSettingPrice">批量设置商品价格</i-button>
        <i-button size="small" class="m-l-5" type="success" :disabled="selectionInventoryIds.length===0" @click="handleSettingDeliver">批量投放商品</i-button>
        <i-button size="small" class="m-l-5" type="error" :disabled="selectionInventoryIds.length===0" @click="handleDeleteProducts">批量移除商品</i-button>
      </i-col>
    </Row>

    <div>
      <Table size="small" stripe :data="list" :loading="tableLoading" :columns="tableColumns" @on-selection-change="selectionChange"></Table>
      <div class="paging_style">
        <Page size="small" :total="total" :page-size="query.pageSize" show-total show-elevator show-sizer :current="query.pageNumber"
          :page-size-opts="pagesizeSetting" @on-change="handlePageChange" @on-page-size-change='changePageSize'></Page>
      </div>
    </div>

    <AddResource ref="addResource" @on-success="hanldeAddSuccess"/>
    <EditResource ref="editResource"/>
    <ProductDetail ref="productDetail"/>
    <BatchCheck ref="batchCheckAdditional" @on-success="hanldeAddSuccess"/>
    <SettingBatchPrice ref="batchSettingPrice" @on-success="hanldeAddSuccess"/>

    <SettingPhoto ref="settingPhoto" @on-success="handleSearch"/>
    <Modal v-model="visiblePreview" width="800" :footer-hide="true">
      <p slot="header" class="text-center">商品主图</p>
      <img-light-box ref="imgLightBox" :showModal="visiblePreview" :sortIndex.sync="sortIndex"></img-light-box>
    </Modal>
  </div>
</template>

<script>
import AddResource from './AddResource'
import EditResource from './EditResource'
import ProductDetail from './ProductDetail'
import BatchCheck from '../additional/BatchCheck'
import SettingPhoto from './SettingPhoto'
import SettingBatchPrice from './SettingBatchPrice'
import ImgLightBox from '@/components/common/ImgVideoLightBox'

import em from '@/utils/enum'

import { getRimStationByAssetIds } from '@/api/rim/station'
import { getPublisherProductAssetList, getResourceTypeList } from '@/api/product/asset'
import { getCloudResource, getInventoryStatus, deleteInventory, getInventoryPhoto, deliverInventorys, deleteInventorys } from '@/api/product/cloudinventory'

export default {
  components: {
    AddResource, EditResource, ProductDetail, BatchCheck, ImgLightBox, SettingPhoto, SettingBatchPrice
  },
  data () {
    return {
      query: {
        pageNumber: 1,
        pageSize: 20,
        publisherId: this.$store.getters.token.userInfo.publisherId,
        assetId: null,
        enabled: null, // 资源状态 0不可用,1可用
        status: null, // 发布状态
        position: null, // 资源位置
        resourcetypeId: null,
        stationId: null,
        keyword: ''
      },

      list: [],
      total: 0,
      pagesizeSetting: [20, 50, 100, 200],

      assetId: -1, // 用于处理选择全部资产时的查询参数
      assetArray: [],
      stationArray: [],
      positionArray: em.positionEnum,
      resourceTypeArray: [],
      statusArray: [
        { value: 1, name: '可用' },
        { value: 0, name: '不可用' },
        { value: -1, name: '冻结' }
      ],
      publishStatusArray: [],
      selectionInventoryIds: [],
      tableLoading: false,
      tableColumns: [
        { type: 'selection', width: 60, align: 'center', fixed: 'left' },
        { title: '编号', align: 'center', key: 'code' },
        { title: '站点', align: 'center', key: 'stationName' },
        { title: '类型', align: 'center', key: 'resourcetypeName' },
        { title: '位置', align: 'center', key: 'positionName', width: 90 },
        { title: '等级', align: 'center', key: 'levelName', width: 80 },
        { title: '楼层', align: 'center', key: 'floor', width: 80 },
        { title: '面积(㎡)', align: 'center', key: 'area', width: 80 },
        {
          title: '制作尺寸(mm)',
          align: 'center',
          width: 120,
          render: (h, params) => {
            return h('span', `${params.row.makewidth} * ${params.row.makeheight}`)
          }
        },
        {
          title: '见光尺寸(mm)',
          align: 'center',
          width: 120,
          render: (h, params) => {
            return h('span', `${params.row.finalwidth} * ${params.row.finalheight}`)
          }
        },
        {
          title: '资源状态',
          align: 'center',
          key: 'enabled',
          width: 80,
          render: (h, params) => {
            const status = this.statusArray.find(x => x.value === params.row.enabled)
            return h('span', status ? status.name : '/')
          }
        },
        {
          title: '发布状态',
          align: 'center',
          key: 'statusName',
          render: (h, params) => {
            const showLabels = [
              h('span', params.row.statusName)
            ]
            // 添加提示
            if (params.row.mark) {
              showLabels.push(
                h('Tooltip', {
                  style: {
                    cursor: 'pointer',
                    marginLeft: '5px'
                  },
                  props: {
                    content: params.row.mark,
                    placement: 'top',
                    maxWidth: 200,
                    transfer: true
                  }
                }, [
                  h('Icon', {
                    props: {
                      type: 'md-help-circle',
                      color: '#ff6600',
                      size: 14
                    }
                  })
                ])
              )
            }

            return h('div', showLabels)
          }
        },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            const buttons = []

            // 待投放、待移除、正在移除、投放职工，不显示该按钮
            if (![1, 3, 4, 5].includes(params.row.status)) {
              buttons.push(h('a', {
                on: {
                  click: () => {
                    this.handleEdit(params.row)
                  }
                }
              }, '编辑'))
              buttons.push(h('Divider', { attrs: { type: 'vertical' } }))
              buttons.push(h('a', {
                style: { color: '#ef4f4f' },
                on: {
                  click: () => {
                    this.handleDelete(params.row)
                  }
                }
              }, '移除'))
              buttons.push(h('Divider', { attrs: { type: 'vertical' } }))
            }

            // 打开新的窗口，预览投放情况
            if (params.row.url) {
              buttons.push(h('a', {
                on: {
                  click: () => {
                    window.open(params.row.url)
                  }
                }
              }, '查看投放'))
              buttons.push(h('Divider', { attrs: { type: 'vertical' } }))
            }

            buttons.push(h('a', {
              on: {
                click: () => {
                  getInventoryPhoto({ inventoryId: params.row.inventoryId }).then(res => {
                    const postData = {
                      startIndex: 0,
                      imgList: res.map(item => { return { fileKey: item.photo } })
                    }
                    this.sortIndex = 0
                    this.$refs.imgLightBox.init(postData)
                    this.visiblePreview = true
                  })
                }
              }
            }, '查看主图'))

            buttons.push(h('Divider', { attrs: { type: 'vertical' } }))
            buttons.push(h('a', {
              on: {
                click: () => {
                  this.handleProductDetail(params.row.inventoryId)
                }
              }
            }, '商品详情'))

            return h('div', buttons)
          }
        }
      ],
      visiblePreview: false,
      sortIndex: 0
    }
  },
  mounted () {
    this.initBaseData()
  },
  methods: {
    initData () {
      this.tableLoading = true
      this.selectionInventoryIds = []

      getCloudResource(this.query).then(res => {
        if (res && !res.errcode) {
          // 待投放、待移除、正在移除、投放职工，不显示该按钮
          res.records.forEach(item => {
            item._disabled = [1, 3, 4, 5].includes(item.status)
          })

          this.list = res.records
          this.total = res.total
          this.query.pageNumber = res.current
        } else {
          this.list = []
          this.total = 0
          this.query.pageNumber = 1
        }
        this.tableLoading = false
      })
    },
    initBaseData () {
      getInventoryStatus().then(res => {
        this.publishStatusArray = []
        for (const key in res) {
          this.publishStatusArray.push({
            value: key,
            name: res[key]
          })
        }
      })

      getPublisherProductAssetList().then(res => {
        if (res && !res.errcode) {
          this.assetArray = res
        }
        this.initStationArray()
        this.getResourceTypeData()
        this.initData()
      })
    },
    // 初始化站点选项
    initStationArray () {
      this.query.stationId = null
      let assetIds = []
      if (this.assetId <= -1) {
        this.assetArray.forEach(item => {
          assetIds = assetIds.concat(this.assemblyRimAssetIds(item.rimAssetIds))
        })
      } else {
        const rimAsset = this.assetArray.find(x => x.id === this.assetId)
        if (rimAsset) {
          assetIds = this.assemblyRimAssetIds(rimAsset.rimAssetIds)
        }
      }
      // 调用mixins中的方法
      getRimStationByAssetIds({ assetIds: JSON.stringify(assetIds) }).then((res) => {
        this.stationArray = res
      })
    },
    assemblyRimAssetIds (data) {
      const ids = []
      if (data.indexOf(',')) {
        data.split(',').forEach(x => {
          ids.push(parseInt(x))
        })
      } else {
        ids.push(parseInt(data))
      }
      return ids
    },
    /**
    * 获取资源类型数据
    */
    getResourceTypeData () {
      const assetIds = this.assetId === -1 ? this.assetArray.map(x => x.id) : [this.assetId]
      getResourceTypeList({ assetIds: JSON.stringify(assetIds) }).then(res => {
        if (res && !res.errcode) {
          this.resourceTypeArray = res
        } else {
          this.resourceTypeArray = []
        }
      })
    },
    handleSearch () {
      this.query.pageNumber = 1
      this.initData()
    },
    handleChangeAsset () {
      this.query.assetId = this.assetId === -1 ? undefined : this.assetId
      this.initStationArray()
      this.getResourceTypeData()
      this.handleSearch()
    },
    handlePageChange (curPage) {
      this.query.pageNumber = curPage
      this.initData()
    },
    changePageSize (pageSize) {
      this.query.pageSize = pageSize
      this.query.pageNumber = 1
      this.initData()
    },
    selectionChange (selection) {
      this.selectionInventoryIds = selection.map(x => x.inventoryId)
    },
    handleAdd () {
      this.$refs.addResource.showModal()
    },
    hanldeAddSuccess () {
      this.handleSearch()
    },
    handleEdit (resourceBean) {
      this.$refs.editResource.showModal(resourceBean)
    },
    handleDelete (item) {
      this.$Modal.confirm({
        title: '确定删除？',
        content: '<p>您确定要将资源 <b>' + item.code + '</b> 从媒体库从删除吗？</p>',
        loading: true,
        onOk: () => {
          const DeleteBean = {
            relateId: item.id,
            type: 1
          }

          deleteInventory(DeleteBean).then(res => {
            this.$Notice.success({ desc: '删除资源成功！' })
            this.handleSearch()
            this.$Modal.remove()
          })
        }
      })
    },
    handleProductDetail (inventoryId) {
      this.$refs.productDetail.showModal(inventoryId)
    },
    handleSettingAdditional () {
      this.$refs.batchCheckAdditional.showModal(this.selectionInventoryIds)
    },
    handleSettingPhoto () {
      this.$refs.settingPhoto.showModal(this.selectionInventoryIds)
    },
    handleSettingPrice () {
      this.$refs.batchSettingPrice.showModal(this.selectionInventoryIds)
    },
    handleSettingDeliver () {
      this.$Modal.confirm({
        title: '操作确认？',
        content: '您确定要批量投放选中的媒体库存吗？ 确认后将自动全部投放！',
        loading: true,
        onOk: () => {
          const saveBean = {
            inventoryIds: this.selectionInventoryIds,
            launchInfo: 1
          }

          deliverInventorys(saveBean).then(res => {
            if (res.success) {
              this.$Notice.success({ desc: '批量投放商品成功！', title: '操作成功' })
              this.$Modal.remove()
              this.handleSearch()
            }
          })
        }
      })
    },
    handleDeleteProducts () {
      this.$Modal.confirm({
        title: '操作确认？',
        content: '<p>您确定要批量 <span class="text-red">移除选中</span> 的媒体库存吗？ 确认后将移除勾选的商品！</p>',
        loading: true,
        onOk: () => {
          deleteInventorys(this.selectionInventoryIds).then(res => {
            if (res.success) {
              this.$Notice.success({ desc: '批量移除商品成功！', title: '操作成功' })
              this.$Modal.remove()
              this.handleSearch()
            }
          })
        }
      })
    }
  }
}
</script>
