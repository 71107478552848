<template>
  <div>
    <Modal v-model="showResourceDetail" width="1000" title="编辑媒体库资源" footer-hide>
      <div class="workplatform-title m-b-10">媒体基础信息</div>
      <Row class="p-b-5" :gutter="8">
        <i-col span="8">
          <label class="title inline" style="width: 80px;">编号 </label>
          {{resourceBean.code}}
        </i-col>
        <i-col span="8">
          <label class="title inline" style="width: 80px;">站点 </label>
          {{resourceBean.stationName}}
        </i-col>
        <i-col span="8">
          <label class="title inline" style="width: 80px">类型 </label>
          {{resourceBean.resourcetypeName}}
        </i-col>
      </Row>
      <Row class="p-b-5" :gutter="8">
        <i-col span="8">
          <label class="title inline" style="width: 80px">位置 </label>
          {{resourceBean.positionName}}
        </i-col>
        <i-col span="8">
          <label class="title inline" style="width: 80px">等级 </label>
          {{resourceBean.levelName}}
        </i-col>
        <i-col span="8">
          <label class="title inline" style="width: 80px">楼层 </label>
          {{resourceBean.floor}}
        </i-col>
      </Row>
      <Row class="p-b-5" :gutter="8">
        <i-col span="8">
          <label class="title inline" style="width: 80px">面积(㎡) </label>
          {{resourceBean.area}}
        </i-col>
        <i-col span="8">
          <label class="title inline">制作尺寸(mm) </label>
          {{`${resourceBean.makewidth} * ${resourceBean.makeheight}`}}
        </i-col>
        <i-col span="8">
          <label class="title inline">见光尺寸(mm) </label>
          {{`${resourceBean.finalwidth} * ${resourceBean.finalheight}`}}
        </i-col>
      </Row>
      <Row class="p-b-5" :gutter="8">
        <i-col span="8">
          <label class="title inline" style="width: 80px">资源状态</label>
          {{getResourceStatus(resourceBean.enabled)}}
        </i-col>
        <i-col span="8">
          <label class="title inline" style="width: 80px">发布状态</label>
          {{resourceBean.statusName}}
        </i-col>
      </Row>

      <!-- <div class="workplatform-title m-b-10 m-t-20">媒体服务项</div>
      <Row class="p-b-5" :gutter="8">
        <i-col span="24" >
          <CheckboxGroup v-model="checkServiceItem">
              <Checkbox v-for="item in serviceItems" :key="item.id" :label="item.id">{{ item.name }}（{{ formatMoney(item.price) }} / {{ item.unitName }}）</Checkbox>
          </CheckboxGroup>
        </i-col>
      </Row> -->

      <div class="workplatform-title m-b-10 m-t-20">
        媒体档期售价<i-button type="success" class="m-l-10" size="small" @click="showBatchEdit=true">批量修改价格</i-button>
      </div>

      <template v-if="isElectronic">
        <Row :gutter="2" class="table-title m-b-5">
          <i-col span="3">-</i-col>
          <i-col span="3">投放天数</i-col>
          <i-col span="3">投放规格(秒)</i-col>
          <i-col span="3">投放次数</i-col>
          <i-col span="3" class="text-center">标价</i-col>
          <i-col span="3" class="text-center">售价</i-col>
          <i-col span="3" class="text-center">结算价</i-col>
          <i-col span="2" class="text-center">是否投放</i-col>
          <i-col v-if="resourceSchedulePrice.length>1" span="1" class="text-right">操作</i-col>
        </Row>
        <Row :gutter="2" v-for="(item,index) in resourceSchedulePrice" :key="index" class="m-b-5">
          <i-col span="3">
            <span>{{ item.scheduleName }}</span>
          </i-col>
          <i-col span="3">
            <InputNumber v-model="item.day" size="small" :max="999999" :min="1" style="width: 100px;" placeholder="请输入投放天数"></InputNumber>
          </i-col>
          <i-col span="3">
            <InputNumber v-model="item.duration" size="small" :max="999999" :min="1" style="width: 100px;" placeholder="请输入投放规格"></InputNumber>
          </i-col>
          <i-col span="3">
            <InputNumber v-model="item.period" size="small" :max="999999" :min="1" style="width: 100px;" placeholder="请输入投放次数"></InputNumber>
          </i-col>
          <i-col span="3" class="text-center">
            <InputNumber v-model="item.originalPrice" size="small" :max="999999" :min="0" style="width: 100px;" placeholder="请输入媒体的标准价格"></InputNumber>
          </i-col>
          <i-col span="3" class="text-center">
            <InputNumber v-model="item.sellingPrice" size="small" :max="999999" :min="0" style="width: 100px;" placeholder="请输入媒体的售卖价格"></InputNumber>
          </i-col>
          <i-col span="3" class="text-center">
            <InputNumber v-model="item.statementPrice" size="small" :max="999999" :min="0" style="width: 100px;" placeholder="请输入媒体的售卖价格"></InputNumber>
          </i-col>
          <i-col span="2" class="text-center">
            <Icon v-if="item.status>0" type="md-checkmark" color="#19be6b" size="18" />
            <span v-else>-</span>
          </i-col>
          <i-col v-if="resourceSchedulePrice.length>1" span="1" class="text-center">
            <a class="text-red" @click="handleDeleteElecSchedule(index)">移除</a>
          </i-col>
        </Row>
        <p><a @click="handleAddSchedulePrice"><Icon type="md-add" />添加新的售卖规格</a></p>

      </template>

      <template v-else>
        <Row :gutter="8" class="table-title m-b-5">
          <i-col span="2"></i-col>
          <i-col span="3">标价</i-col>
          <i-col span="3">售价</i-col>
          <i-col span="3">结算价</i-col>
          <i-col span="1" class="text-center">投放</i-col>
          <i-col span="2"></i-col>
          <i-col span="3">标价</i-col>
          <i-col span="3">售价</i-col>
          <i-col span="3">结算价</i-col>
          <i-col span="1" class="text-center">投放</i-col>
        </Row>

        <Row :gutter="2" v-for="index in (resourceSchedulePrice.length/2)" :key="index">
          <i-col span="2" class="m-b-5 text-center">{{ resourceSchedulePrice[index*2-2].scheduleName }}</i-col>
          <i-col span="3" class="m-b-5">
            <InputNumber v-model="resourceSchedulePrice[index*2-2].originalPrice" size="small" :max="999999" :min="0" style="width: 100px;" placeholder="请输入媒体的标准价格"></InputNumber>
          </i-col>
          <i-col span="3" class="m-b-5">
            <InputNumber v-model="resourceSchedulePrice[index*2-2].sellingPrice" size="small" :max="999999" :min="0" style="width: 100px;" placeholder="请输入媒体的售卖价格"></InputNumber>
          </i-col>
          <i-col span="3" class="m-b-5">
            <InputNumber v-model="resourceSchedulePrice[index*2-2].statementPrice" size="small" :max="999999" :min="0" style="width: 100px;" placeholder="请输入媒体的结算价格"></InputNumber>
          </i-col>
          <i-col span="1" class="m-b-5 text-center">
            <Icon v-if="resourceSchedulePrice[index*2-2].status>0" type="md-checkmark" color="#19be6b" size="18" />
            <span v-else>-</span>
          </i-col>

          <i-col span="2" class="m-b-5 text-center">{{ resourceSchedulePrice[index*2-1].scheduleName }}</i-col>
          <i-col span="3" class="m-b-5">
            <InputNumber v-model="resourceSchedulePrice[index*2-1].originalPrice" size="small" :max="999999" :min="0" style="width: 100px;" placeholder="请输入媒体的标准价格"></InputNumber>
          </i-col>
          <i-col span="3" class="m-b-5">
            <InputNumber v-model="resourceSchedulePrice[index*2-1].sellingPrice" size="small" :max="999999" :min="0" style="width: 100px;" placeholder="请输入媒体的售卖价格"></InputNumber>
          </i-col>
          <i-col span="3" class="m-b-5">
            <InputNumber v-model="resourceSchedulePrice[index*2-1].statementPrice" size="small" :max="999999" :min="0" style="width: 100px;" placeholder="请输入媒体的结算价格"></InputNumber>
          </i-col>
          <i-col span="1" class="m-b-5 text-center">
            <Icon v-if="resourceSchedulePrice[index*2-1].status>0" type="md-checkmark" color="#19be6b" size="18" />
            <span v-else>-</span>
          </i-col>
        </Row>
      </template>

      <Row class="text-center">
        <i-col span="24">
          <i-button class="m-t-10" type="primary" style="width: 150px;" @click="handleSave(false)">保存修改</i-button>
          <i-button class="m-t-10 m-l-10" type="success" style="width: 150px;" @click="handleSave(true)">提交并投放</i-button>
        </i-col>
      </Row>
    </Modal>

    <Modal v-model="showBatchEdit" width="400" title="批量修改媒体价格" footer-hide>
      <Row class="m-b-10">
        <i-col span="4" class="p-t-5">标价</i-col>
        <i-col span="8">
          <InputNumber v-model="batchBean.originalPrice" :max="999999" :min="0.01" style="width: 100px;" placeholder="请输入媒体的标准价格"></InputNumber>
        </i-col>
        <i-col span="4" class="p-t-5">售价</i-col>
        <i-col span="8">
          <InputNumber v-model="batchBean.sellingPrice" :max="999999" :min="0.01" style="width: 100px;" placeholder="请输入媒体的售卖价格"></InputNumber>
        </i-col>
      </Row>
      <Row class="m-b-20">
        <i-col span="4" class="p-t-5">结算价</i-col>
        <i-col span="8">
          <InputNumber v-model="batchBean.statementPrice" :max="999999" :min="0.01" style="width: 100px;" placeholder="请输入媒体的结算价格"></InputNumber>
        </i-col>
      </Row>

      <template v-if="isElectronic">
        <Divider>设置电子屏默认售卖规则</Divider>
        <Row class="m-b-10">
          <i-col span="4" class="p-t-5">售卖天数</i-col>
          <i-col span="8">
            <InputNumber v-model="batchBean.day" :max="999999" :min="1" style="width: 100px;" placeholder="请输入媒体的售卖天数"></InputNumber>
          </i-col>
        </Row>
        <Row class="m-b-10">
          <i-col span="4" class="p-t-5">投放规格</i-col>
          <i-col span="8">
            <InputNumber v-model="batchBean.duration" :max="999999" :min="1" style="width: 100px;" placeholder="请输入媒体的投放规格"></InputNumber>
          </i-col>
          <i-col span="4" class="p-t-5">投放次数</i-col>
          <i-col span="8">
            <InputNumber v-model="batchBean.period" :max="999999" :min="1" style="width: 100px;" placeholder="请输入媒体的投放次数"></InputNumber>
          </i-col>
        </Row>
      </template>

      <Row class="m-b-10">
        <i-col span="20" offset="4">
          <i-button type="success" long @click="handleBatchEdit">开始批量修改</i-button>
        </i-col>
      </Row>
    </Modal>
  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { getCloudInventory, updateInventoryList } from '@/api/product/cloudinventory'

export default {
  data () {
    return {
      showResourceDetail: false,
      showBatchEdit: false,

      resourceBean: {},
      statusArray: [
        { value: 1, name: '可用' },
        { value: 0, name: '不可用' },
        { value: -1, name: '冻结' }
      ],

      isElectronic: false,
      batchBean: {
        originalPrice: 0.01,
        sellingPrice: 0.01,
        statementPrice: 0.01, // 结算价
        day: 1,
        duration: 1,
        period: 1
      },
      // serviceItems: [],
      // checkServiceItem: [],
      resourceSchedulePrice: []
    }
  },
  mounted () {
    // const query = {
    //   publisherId: this.$store.getters.token.userInfo.publisherId
    // }
    // getCloudServiceItem(query).then(res => {
    //   this.serviceItems = res
    // })
  },
  methods: {
    showModal (resourceBean) {
      this.showResourceDetail = true
      this.resourceBean = resourceBean
      this.resourceSchedulePrice = []
      this.checkServiceItem = resourceBean.cloudServiceItemVOS.map(x => x.id)

      // 判断是否为电子屏
      this.isElectronic = resourceBean.resourcetypeId > 300 && resourceBean.resourcetypeId < 400
      this.initData()
    },
    initData () {
      const editBean = {
        relateId: this.resourceBean.id,
        type: 1
      }

      getCloudInventory(editBean).then(res => {
        this.resourceSchedulePrice = res
      })
    },
    getResourceStatus (enabled) {
      const status = this.statusArray.find(x => x.value === enabled)
      return status ? status.name : '/'
    },
    handleSave (isDeliver) {
      // 如果是电子屏，那么需要格式化所有的scheduleName
      if (this.isElectronic) {
        this.resourceSchedulePrice.forEach(element => {
          element.scheduleName = element.day + '天'
        })
      }

      const updateBean = {
        cloudSchedulePO: this.resourceSchedulePrice,
        isDeliver: isDeliver
      }
      const successMsg = isDeliver ? '并成功投放，请注意查看商品投放状态！' : ''

      updateInventoryList(updateBean).then(res => {
        this.$Notice.success({ desc: '保存媒体价格成功！' + successMsg })
        this.showResourceDetail = false
      })
    },
    handleAddSchedulePrice () {
      const defaultPrice = this.resourceSchedulePrice[0]

      this.resourceSchedulePrice.push({
        day: 7,
        duration: 5,
        enabled: true,
        inventoryId: defaultPrice.inventoryId,
        originalPrice: 0.01,
        period: 20,
        resourceId: defaultPrice.resourceId,
        scheduleName: '7天',
        sellingPrice: 0.01,
        statementPrice: 0.01,
        skuId: -1,
        status: 0
      })
    },
    handleDeleteElecSchedule (deleteIndex) {
      this.resourceSchedulePrice.splice(deleteIndex, 1)
    },
    formatMoney (number) {
      return toMoney(number)
    },
    handleBatchEdit () {
      this.resourceSchedulePrice.forEach(element => {
        element.originalPrice = this.batchBean.originalPrice
        element.sellingPrice = this.batchBean.sellingPrice
        element.statementPrice = this.batchBean.statementPrice

        if (this.isElectronic) {
          element.day = this.batchBean.day
          element.duration = this.batchBean.duration
          element.period = this.batchBean.period
        }
      })

      this.showBatchEdit = false
    }
  }
}
</script>
